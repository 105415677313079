import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import config from "../config";
import moment from "moment";
import { Modal } from "antd";
import axios from "axios";

class LiveTrackingStore {
  liveTrackingData = [];
  totalvehstate = [];
  total = 0;
  loading = false;
  rootStore;
  selectedTab;
  timer = 30;
  pgcount = 1;
  offset = 0;
  LatLongDistArray = [];
  TotalDistance = undefined;
  TotalTime = undefined;
  selectedAdrressArray = [];
  AutorunVar = false;
  AutorunPopup = false;
  LatLongArray = [];
  error = false;
  showNearVehiclePopup = false;
  totalCount = null;
  veicleList = [];
  GeofenceList = [];
  NearVeicleList = [];
  MapClickPoints = [];
  stopIdleArray = [];
  offlineDataNewObj = [];
  vehiclesArray = [];
  vehiclePositionArray = [];
  vehiclePositionData = [];

  vehiclePosition = [];
  Marker = undefined;

  geofenceListArray = [];
  getSelectedGroups = [];
  selectedVehicles = [];
  vehicleTrackingInfo = [];

  daySummaryObj = {};
  driverPerformanceData = {};

  constructor(rootStore) {
    makeObservable(this, {
      liveTrackingData: observable,
      totalvehstate: observable,
      driverPerformanceData: observable,
      total: observable,
      loading: observable,
      timer: observable,
      LatLongDistArray: observable,
      TotalDistance: observable,
      TotalTime: observable,
      selectedAdrressArray: observable,
      AutorunVar: observable,
      AutorunPopup: observable,
      LatLongArray: observable,
      showNearVehiclePopup: observable,
      veicleList: observable,
      GeofenceList: observable,
      NearVeicleList: observable,
      MapClickPoints: observable,
      selectedVehicles: observable,
      daySummaryObj: observable,
      vehicleTrackingInfo: observable,
      getSelectedGroups: observable,
      vehiclesArray: observable,
      vehiclePositionArray: observable,
      vehiclePositionData: observable,
      Marker: observable,
      offlineDataNewObj: observable,
      geofenceListArray: observable,
      stopIdleArray: observable,
      vehiclePosition: observable,
      totalCount: observable,
      daySummary: action,
      addData: action,
      loadData: action,
      setDistLatLong: action,
      // getDistance: action,
      // toRadian: action,
      GetLocationAddress: action,
      setAutorunVar: action,
      RemoveDistData: action,
      updateSelectedAdrressArray: action,
      setLatLongDistArray: action,
      AddSelectedAdrressArray: action,
      setShowNearVehiclePopup: action,
      getVehicleData: action,
      getNearestVehicleServ: action,

      setOnMapClickPoints: action,

      getVehiclesUsingCompanyId: action,
      getAddressUsingLatLong: action,

      setAutorunPopup: action,
      setMarker: action,

      getGofenceList: action,
      getIcon: action,

      sampleExcel: action,
      addGeofenceByExcel: action,
      getTypeData: action,
      exportPoiVehicle: action,
      getClusterVehicleData: action,
      getDriverPerformance: action,
      getVehicleTrackingInfo: action,
      getSelectedVehicles: action,
      getVehiclePositionofVehicle: action,
    });
    this.rootStore = rootStore;
  }

  async addData({ jTypeName }) {
    var postData = { jobType: { jTypeName: jTypeName } };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=addJobtype", "postData=" + base64Input);
    console.log("fuel detis ", response.data.data);
  }

  async getVehicleTrackingInfo({ vehicleId, companyId }) {
    var postData = { vehicleId: vehicleId, companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleposition",
        "postData=" + base64Input
      );
    this.vehicleTrackingInfo = response?.data;
    console.log("vehicletrackinginfo ", response.data.data);
  }

  async handleVehicleImage(data) {
    let liveTrackingData = data;
    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];

      //  var diff = new Date(r.currtime) - new Date(r.updatedate);
      const diff = moment(r.currtime).diff(r.updatedate, "minutes");
      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      let defaultImage = "car-icon-blue.png";
      if (diff >= 90) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
        liveTrackingData[i].rowBackground = "gray";
        liveTrackingData[i].currentStatus = "No Data";
        defaultImage = "default-gray.png";
      
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        console.log(defaultImage)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "Idle";
        defaultImage = "default-yellow.png";
      } else if (r.acc == 0) {
       
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "Stopped";
        defaultImage = "default-red.png";
      } else if (r.acc == null || r.acc == 1 && r.speed > 0) {
       
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "Running";
        defaultImage = "default-green.png";
      }
     
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = defaultImage;
      }

      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      }
       catch (err) {
       
        // console.log(defaultImage,"defaultImage")
        liveTrackingData[i].mapImage = defaultImage //"car-icon-blue.png";
      }
    });
    return liveTrackingData;
  }

  async loadData({ companyId, poupSearch, vehicleStatus }) {
    var postData = {
      companyId: companyId,
      poupSearch: poupSearch,
      vehicleStatus: vehicleStatus,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getdata2",
        "postData=" + base64Input
      );

    var liveTrackingData = response?.data?.data?.livetrack;
    let finalLiveTrackingData = await this.handleVehicleImage(liveTrackingData);

    // Object.keys(liveTrackingData).map((e, i) => {
    //   let r = liveTrackingData[i];

    //   //  var diff = new Date(r.currtime) - new Date(r.updatedate);
    //   const diff = moment(r.currtime).diff(r.updatedate, "minutes");
    //   var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
    //   if (diff >= 90) {
    //     liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
    //     liveTrackingData[i].rowBackground = "gray";
    //     liveTrackingData[i].currentStatus = "nodata";
    //   } else if (r.acc == 1 && r.speed == 0) {
    //     //idle)
    //     liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
    //     liveTrackingData[i].rowBackground = "yellow";
    //     liveTrackingData[i].currentStatus = "idle";
    //   } else if (r.acc == 0) {
    //     liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
    //     liveTrackingData[i].rowBackground = "red";
    //     liveTrackingData[i].currentStatus = "stop";
    //   } else if (r.acc == 1 && r.speed > 0) {
    //     liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
    //     liveTrackingData[i].rowBackground = "green";
    //     liveTrackingData[i].currentStatus = "running";
    //   }
    //   liveTrackingData[i].mapImage = liveTrackingData[i].listImage;

    //   try {
    //     require("../assets/images/detailtracking/" +
    //       liveTrackingData[i].listImage);
    //   } catch (err) {
    //     liveTrackingData[i].listImage = "car-icon-blue.png";
    //   }

    //   try {
    //     require("../assets/images/livetracking/" +
    //       liveTrackingData[i].mapImage);
    //   } catch (err) {
    //     liveTrackingData[i].mapImage = "car-icon-blue.png";
    //   }
    // });
    // console.log(liveTrackingData);
    this.liveTrackingData = finalLiveTrackingData;
    // console.log(this.getSelectedGroups )
    // this.liveTrackingData=this.getSelectedGroups;
    this.totalvehstate = response?.data?.data?.totalvehstate;
    if (this.selectedVehicles) {
      let updatedInfo = this.selectedVehicles.map((recordInfo) => {
        let matchingRecordA = this.liveTrackingData.find(
          (recordA) => recordA.vehicleId === recordInfo.vehicleId
        );
        if (matchingRecordA) {
          for (let prop in matchingRecordA) {
            recordInfo[prop] = matchingRecordA[prop];
          }
        }
        return recordInfo;
      });
      this.selectedVehicles = updatedInfo;
      // console.log(updatedInfo);
    }
    // console.log(this.selectedVehicles);
  }

  //For Groups in live tracking
  async getSelectedVehicles(companyId, userId, isadmin, value) {
    var postData = {
      tokenId: "",
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
      status: "searchvod",
      vehicleNo: "",
      driverName: "",
      searchbyUserId: null,
      vehicleIds: value,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getdata2",
        "postData=" + base64Input
      );
    // console.log(response);
    // this.getSelectedGroups = response.data.data.searchdata;
    let groupData = response.data.data.searchdata;
    let finalGroupData = await this.handleVehicleImage(groupData);
    this.liveTrackingData = finalGroupData;
    // console.log(this.liveTrackingData);
    return response;
  }

  async getDriverPerformance(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getDriverPerformance",
        "postData=" + base64Input
      );
    // console.log(response)
    // console.log(response.data?.data);
    this.driverPerformanceData = response.data?.data;
    // return response?.data?.data
  }

  async getClusterVehicleData(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=clusterVehicleData",
        "postData=" + base64Input
      );
    // console.log(response)
    return response?.data?.data;
  }

  async GetLocationAddress(data) {
    // var data = {};
    //  data.latitude = parseFloat(d.lat);
    //  data.longitude = parseFloat(d.lng);
    const base64Input = await base64_encode(JSON.stringify(data));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=liveLocation&a=getLocation",
        "postData=" + base64Input
      );
    this.selectedAddress = response.data.data.display_name;
    this.selectedAdrressArray.push(this.selectedAddress);
    this.AutorunVar = true;

    // console.log(this.selectedAdrressArray);
  }
  async setDistLatLong(data) {
    this.LatLongArray = data;
    var totalDists = 0;
    var traveltotalTime = 0;
    if (data.length > 1) {
      for (var i = 0; i < data.length - 1; i++) {
        // var first = data[data.length - 2];
        // var next = data[data.length - 1];

        var first = data[i];
        var next = data[i + 1];
        console.log("first ", first, "next ", next);

        await fetch(
          "https://maps.googleapis.com/maps/api/distancematrix/json?key=AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo-zlr8VM&origins=" +
            first.lat +
            "," +
            first.lng +
            "&destinations=" +
            next.lat +
            "," +
            next.lng
        )
          .then((res) => res.json())
          .then((resdata) => {
            console.log(resdata);
            console.log(resdata.destination_addresses[0]);

            // var distance = (data.rows[0].elements[0].distance.text.split(" "));
            var distance = resdata.rows[0].elements[0].distance.value;
            var time = resdata.rows[0].elements[0].duration.value;
            var responseData = { distance: distance, time: time };
            this.LatLongDistArray.push(responseData);
            // this.GetLocationAddress(this.LatLongArray[this.LatLongArray.length-1]);
          })
          .catch((err) => {
            // if(i == data.length){
            Modal.warning({
              title: "Alert",
              content: "Please Try After Sometime",
            });
            // }
            console.log(err?.message);
            this.error = true;
          });
        // console.log(response.destination_addresses[0])
      }
    }

    if (this.LatLongDistArray) {
      this.LatLongDistArray.map((l) => {
        totalDists += l.distance;
        traveltotalTime += l.time;

        console.log("l.distance " + l.distance);
      });
      this.TotalDistance = parseFloat((totalDists / 1000).toFixed(2));
      this.TotalTime = parseFloat((traveltotalTime / 60).toFixed(2));
      //     console.log("this.TotalDistance"+this.TotalDistance);
      this.AutorunVar = true;
    }
  }

  setAutorunVar(data) {
    this.AutorunVar = data;
  }
  RemoveDistData() {
    this.selectedAdrressArray = [];
    this.TotalDistance = undefined;
    this.TotalTime = undefined;
    this.LatLongDistArray = [];
    this.LatLongArray = [];
  }
  async updateSelectedAdrressArray(i) {
    var selectedAdrressArray = [];
    var LatLongArray = [];
    var delIndex = i;
    selectedAdrressArray = this.selectedAdrressArray.filter((e, index) => {
      return index != delIndex;
    });
    this.selectedAdrressArray = selectedAdrressArray;
    // this.AutorunVar = true;

    LatLongArray = this.LatLongArray.filter((m, ind) => {
      return ind != delIndex;
    });
    this.LatLongArray = LatLongArray;
    this.TotalDistance = undefined;
    this.TotalTime = undefined;
    this.LatLongDistArray = [];
    this.setDistLatLong(this.LatLongArray);
  }
  setLatLongDistArray() {
    this.LatLongDistArray = [];
  }
  AddSelectedAdrressArray(data) {
    var selectedAdrressArray = [];
    selectedAdrressArray = this.selectedAdrressArray.filter((e, index) => {
      return index != data;
    });
    this.selectedAdrressArray = selectedAdrressArray;
    //   this.selectedAdrressArray.splice(this.selectedAdrressArray[data],0,"");
    // console.log(this.selectedAdrressArray);
  }
  setShowNearVehiclePopup(data) {
    this.showNearVehiclePopup = data;
  }

  async getVehicleData(id) {
    var data = {};
    data.companyId = id;

    const base64Input = await base64_encode(JSON.stringify(data));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=getvehicledata", "postData=" + base64Input);
    this.veicleList = response.data.data.vehicles;
    this.GeofenceList = response.data.data.geofences;
    // this.selectedAdrressArray.push(this.selectedAddress);
    // this.AutorunVar = true;

    // console.log(this.selectedAdrressArray);
  }
  async getNearestVehicleServ(data) {
    const base64Input = await base64_encode(JSON.stringify(data));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=nearestvehicle", "postData=" + base64Input);
    this.NearVeicleList = response.data.data;
    // this.GeofenceList = response.data.data.geofences;
    console.log(response);
    return response;
  }

  // setOnMapClickPoints(data1,data2){
  // this.MapClickPoints[0] = data1;
  // this.MapClickPoints[1] = data2;
  // }

  setOnMapClickPoints(data) {
    this.MapClickPoints = data;
  }

  async getVehiclesUsingCompanyId({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    // console.log(response.data.data)
    this.vehiclesArray = response.data.data;
  }

  async poiCurrentVehicleUsingCompanyId({ companyId }) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=poi&a=poiCurrentVehicle", "postData=" + base64Input);
    console.log(response.data.data);
    this.vehiclePositionArray = response.data.data;
  }

  // async getAddressUsingLatLong({latitude,longitude}){
  //   // window.location.href = 'http://44.232.69.150/reverse.php?lat='+5.1966666667+'&lon='+71.7785000000+'&format=jsonv2&accept-language=en';
  //   // window.location.href =  'https://maps.googleapis.com/maps/api/geocode/json?sensor=false&language=en&'+'key=AIzaSyAu2h2n_gORgPafUoADJBdEuyjN-zlr8VM &latlng='+5.1966666667+','+71.7785000000

  //   const response  =  'https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&key=AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo'
  //   console.log(response.json())
  //   return response
  // }
  // async  getAddressUsingLatLong({ latitude, longitude }) {
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAu2h2n_gORgPafUoADJBdEuyjN-zlr8VM`
  //   );
  //   const data = await response.json();
  //   if (data.status === 'OK') {
  //     return data.results[0].formatted_address;
  //   } else {
  //     return null;
  //   }
  // }

  async getAddressUsingLatLong(shapeType, centerLat, centerLng, radius) {
    const apiKey = "AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo";
    let url;

    if (shapeType === "circle") {
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${centerLat},${centerLng}&key=${apiKey}`;
    } else if (shapeType === "polygon") {
      // For polygons, you can pass multiple lat/lng pairs as path parameter
      const path = `${centerLat},${centerLng}`;
      // url = `https://maps.googleapis.com/maps/api/geocode/json?path=${path}&key=${apiKey}`;
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${centerLat},${centerLng}&key=${apiKey}`;
    } else if (shapeType === "rectangle") {
      // For rectangles (square is a type of rectangle), you can use the center lat/lng directly
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${centerLat},${centerLng}&key=${apiKey}`;
    }

    const response = await fetch(url);
    const data = await response.json();
    console.log(data);
    if (data.status === "OK") {
      return data.results[0].formatted_address;
    } else {
      return null;
    }
  }

  // async addGeofenceDrawing(data){

  //   var postData = {"geosetting":data};
  //   console.log(postData)
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   var response = await postApi
  //     .getClient()
  //     .post("index.php?c=geofence&a=update", "postData=" + base64Input);
  //   console.log(response)

  //   // this.vehiclePositionArray = response.data.data

  // }

  // Import the necessary libraries/modules, if not already imported.
  // For example, import { base64_encode } from 'your-base64-library';
  // import postApi from 'your-post-api-library';

  async addGeofenceDrawing(data) {
    try {
      const postData = { geosetting: data };
      const postDataJSONString = JSON.stringify(postData);
      const base64Input = btoa(
        unescape(encodeURIComponent(postDataJSONString))
      );
      const encodedBase64Input = encodeURIComponent(base64Input);
      const response = await postApi
        .getClient()
        .post(
          "index.php?c=geofence&a=update",
          "postData=" + encodedBase64Input
        );
      return response;
      // console.log(response);
    } catch (error) {
      // Handle any errors that occur during the process.
      console.error(error);
    }
  }

  async getstopidle(data) {
    console.log(data);
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trip&a=getstopidle", "postData=" + base64Input);
    console.log(
      response.data.StopIdleData,
      response.StopIdleData,
      response.data.data.StopIdleData
    );
    this.stopIdleArray = response.data.data.StopIdleData;
  }

  async getGofenceList({ companyId, searchField, offset, limit }) {
    console.log(offset, limit);
    this.loading = true;
    var postData = {
      companyId: companyId,
      searchField: searchField ? searchField : "",
      offset,
      limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=geoData", "postData=" + base64Input);
    // console.log(response.data.data)
    // console.log(response);
    this.geofenceListArray = response?.data.data.geofence;
    this.total = parseInt(response?.data?.data?.TotalCount);
    // console.log(response?.data?.data?.TotalCount)
    this.loading = false;
  }
  setAutorunPopup(data) {
    this.AutorunPopup = data;
  }

  async deleteGeofence(data) {
    var postData = { geosetting: data };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=delete", "postData=" + base64Input);
    // console.log(response.data.data)
    console.log(response);
    return response;
  }

  setMarker(data) {
    this.Marker = data;
  }
  getIcon(data) {
    var r = data;
    var lastReportingTime = r.updatedate;
    // var serverCurrentTime = r.currtime ;
    var serverCurrentTime = r.currtime;
    var diff = new Date(serverCurrentTime) - new Date(lastReportingTime);

    diff /= 60000; //diff convert in minutes.
    /*console.log(lastReportingTime);
  console.log(serverCurrentTime);
  console.log(diff);*/

    if (
      r.vehicleTypeNew == "Truck" ||
      r.vehicleTypeNew == "Trailer" ||
      r.vehicleTypeNew == "Reefer" ||
      r.vehicleTypeNew == "Generator"
    ) {
      //for truck

      if (diff >= 90) {
        return require("../assets/images/livetracking/Truck-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Truck-yellow.png");
      } else if (r.acc == 0) {
        //stopped
        return require("../assets/images/livetracking/Truck-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Truck-green.png");
      }
    } else if (
      r.vehicleTypeNew == "SchoolBus" ||
      r.vehicleTypeNew == "Mini Bus" ||
      r.vehicleTypeNew == "Tourist Bus"
    ) {
      //for SchoolBus

      if (diff >= 90) {
        return require("../assets/images/livetracking/Mini-Bus-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        // return FLEET_SERVER_URL + "images/vehicles/bus-icon-yellow.png";
        return require("../assets/images/livetracking/Mini-Bus-yellow.png");
      } else if (r.acc == 0) {
        //stopped
        // return FLEET_SERVER_URL + "images/vehicles/bus-icon-red.png";
        return require("../assets/images/livetracking/Mini-Bus-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        // return FLEET_SERVER_URL + "images/vehicles/bus-icon-green.png";
        return require("../assets/images/livetracking/Mini-Bus-green.png");
      }
    } else if (r.vehicleTypeNew == "Car") {
      //for car

      if (diff >= 90) {
        return require("../assets/images/livetracking/Car-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Car-yellow.png");
      } else if (r.acc == 0) {
        //stopped
        return require("../assets/images/livetracking/Car-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Car-green.png");
      }
    } else if (
      r.vehicleTypeNew == "PickUp" ||
      r.vehicleTypeNew == "3Ton PickUp" ||
      r.vehicleTypeNew == "5Ton PickUp" ||
      r.vehicleTypeNew == "10Ton PickUp" ||
      r.vehicleTypeNew == "Hilux" ||
      r.vehicleTypeNew == "Bobcat" ||
      r.vehicleTypeNew == "Canter" ||
      r.vehicleTypeNew == "Machine"
    ) {
      if (diff >= 90) {
        return require("../assets/images/livetracking/PickUp-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/PickUp-yellow.png");
      } else if (r.acc == 0) {
        //stopped
        return require("../assets/images/livetracking/PickUp-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/PickUp-green.png");
      }
    } else if (
      r.vehicleTypeNew == "Van" ||
      r.vehicleTypeNew == "Hiace" ||
      r.vehicleTypeNew == "Urvan" ||
      r.vehicleTypeNew == "Tanker"
    ) {
      if (diff >= 90) {
        return require("../assets/images/livetracking/Van-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Van-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Van-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Van-green.png");
      }
    } else if (r.vehicleTypeNew == "Crane") {
      //for Crane

      if (diff >= 90) {
        return require("../assets/images/livetracking/Crane-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Crane-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Crane-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Crane-green.png");
      }
    } else if (r.vehicleTypeNew == "Bulldozer") {
      //for Bulldozer

      if (diff >= 90) {
        return require("../assets/images/livetracking/Bulldozer-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Bulldozer-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Bulldozer-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Bulldozer-green.png");
      }
    } else if (r.vehicleTypeNew == "Dumper") {
      //for JCB

      if (diff >= 90) {
        return require("../assets/images/livetracking/Dumper-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Dumper-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Dumper-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Dumper-green.png");
      }
    } else if (r.vehicleTypeNew == "Excavator") {
      //for Excavator

      if (diff >= 90) {
        return require("../assets/images/livetracking/Excavator-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Excavator-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Excavator-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Excavator-green.png");
      }
    } else if (r.vehicleTypeNew == "Forklif") {
      //for Forklif

      if (diff >= 90) {
        return require("../assets/images/livetracking/Forklif-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Forklif-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Forklif-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Forklif-green.png");
      }
    } else if (r.vehicleTypeNew == "Bobcat") {
      //for Bobcat
    } else if (r.vehicleTypeNew == "Roller") {
      //for Bobcat

      if (diff >= 90) {
        return require("../assets/images/livetracking/Roller-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Roller-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Roller-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Roller-green.png");
      }
    }
    // else if (r.vehicleTypeNew == "Canter") {//for canter

    //     if (diff >= 90) {
    //         return '<img src="public/fleet/images/detailtracking/canter-gray-icon.png" height="16px" width="30px" />&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 1 && r.speed == 0) {//idle
    //         return '<img src="public/fleet/images/detailtracking/canter-yellow-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 0 || r.acc == null) {//stopped
    //         return '<img src="public/fleet/images/detailtracking/canter-red-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 1 && r.speed > 0) {//running
    //         return '<img src="public/fleet/images/detailtracking/canter-green-icon.png" height="16px"  width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    // }
    else if (
      r.vehicleTypeNew == "Boom Loader" ||
      r.vehicleTypeNew == "Shovel"
    ) {
      //for  Boom Loader

      if (diff >= 90) {
        return require("../assets/images/livetracking/Boom-Loader-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Boom-Loader-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Boom-Loader-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Boom-Loader-green.png");
      }
    }
    // else if (r.vehicleTypeNew == "Generator") {//for  Generator

    //     if (diff >= 90) {
    //         return '<img src="public/fleet/images/detailtracking/generator-gray-icon.png" height="16px" width="30px" />&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 1 && r.speed == 0) {//idle
    //         return '<img src="public/fleet/images/detailtracking/generator-yellow-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 0 || r.acc == null) {//stopped
    //         return '<img src="public/fleet/images/detailtracking/generator-red-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.acc == 1 && r.speed > 0) {//running
    //         return '<img src="public/fleet/images/detailtracking/generator-green-icon.png" height="16px"  width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    // }
    // else if (r.vehicleTypeNew == "Machine") {//for  Machine

    //     if (diff >= 90) {
    //         return '<img src="public/fleet/images/detailtracking/machine-gray-icon.png" height="16px" width="30px" />&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.get('acc') == 1 && r.get('speed') == 0) {//idle
    //         return '<img src="public/fleet/images/detailtracking/machine-yellow-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.get('acc') == 0 || r.get('acc') == null) {//stopped
    //         return '<img src="public/fleet/images/detailtracking/machine-red-icon.png" height="16px" width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    //     else if (r.get('acc') == 1 && r.get('speed') > 0) {//running
    //         return '<img src="public/fleet/images/detailtracking/machine-green-icon.png" height="16px"  width="30px"/>&nbsp' + r.get('vehicleNo');
    //     }
    // }
    else if (r.vehicleTypeNew == "JCB") {
      //for  JCB

      if (diff >= 90) {
        return require("../assets/images/livetracking/JCB-gray.png");
      } else if (r.acc == 1 && r.get("speed") == 0) {
        //idle
        return require("../assets/images/livetracking/JCB-yellow.png");
      } else if (r.acc == 0 || r.get("acc") == null) {
        //stopped
        return require("../assets/images/livetracking/JCB-red.png");
      } else if (r.acc == 1 && r.get("speed") > 0) {
        //running
        return require("../assets/images/livetracking/JCB-green.png");
      }
    } else if (r.vehicleTypeNew == "Motorcycle") {
      //for  Motorcycle

      if (diff >= 90) {
        return require("../assets/images/livetracking/Motorcycle-gray.png");
      } else if (r.acc == 1 && r.speed == 0) {
        //idle
        return require("../assets/images/livetracking/Motorcycle-yellow.png");
      } else if (r.acc == 0 || r.acc == null) {
        //stopped
        return require("../assets/images/livetracking/Motorcycle-red.png");
      } else if (r.acc == 1 && r.speed > 0) {
        //running
        return require("../assets/images/livetracking/Motorcycle-green.png");
      }
    }
  }

  async sampleExcel() {
    window.location.href = window.location.href =
      config.fleetURL + "/appRes/excel/geofencesample1.xlsx";
  }

  async addGeofenceByExcel(excelFile) {
    this.excelLoadig = true;
    var postData = excelFile;
    console.log(postData);
    console.log(excelFile);
    console.log(this.excelLoadig);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=geofence&a=uploadGeofenceExcel",
        "postData=" + base64Input
      );
    this.excelLoadig = false;
    return response.data;
  }

  async daySummary({ currentDate, companyId, vehicleId }) {
    var postData = {
      pollmin: 90,
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: currentDate,
      toDate: currentDate,
      offset: 0,
      limit: 50,
      dateType: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=daysummary", "postData=" + base64Input);
    console.log(response.data.data.daysummary, response);
    this.daySummaryObj = response.data?.data?.daysummary[0];
  }

  async getTypeData({ latlong, type }) {
    // const apiUrl = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latlong[0]},${latlong[1]}&radius=5000&type=${type}&key=${"AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo"}`
    // axios.get(apiUrl)
    //   .then(response => {
    //     // Process the data here
    //     console.log(response.data);

    //   })
    //   .catch(error => {
    //     console.error("Error fetching data:", error);
    //   });
    //   console.log(apiUrl.data)
    //   return apiUrl.data

    const apiUrl = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${
      latlong[0]
    },${
      latlong[1]
    }&radius=5000&type=${type}&key=${"AIzaSyAYh_6XQRV70kMX2LdZ9B1fd4qOhTgvfIo"}`;

    try {
      const response = await axios.get(apiUrl);
      // Process the data here
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  exportPoiVehicle(vehicleId) {
    window.location.href =
      config.baseURL +
      "index.php?c=poi&a=exportpoivehicle&vid=" +
      vehicleId.join(",") +
      "&zoneIds=";
  }

  async getLocationUrl(vehicleId) {
    var postData = vehicleId;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=liveLocation&a=getVehicleId",
        "postData=" + base64Input
      );
    console.log(response, "post");
    return response?.data?.data;
  }

  async addLiveLocation(liveLocationData) {
    console.log(" data", liveLocationData);
    var postData = liveLocationData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=liveLocation&a=update", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getVehiclePositionofVehicle({ vehicleId, companyId, isadmin, userId }) {
    var postData = {
      tokenId: "",
      vehicleId: vehicleId,
      userId: userId,
      isadmin: isadmin,
      companyId: companyId,
      refreshduration: "30",
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=getvehicleposition",
        "postData=" + base64Input
      );
    this.vehiclePositionData = response.data.data?.vehicleposition;

    return this.vehiclePositionData;
  }
}
export default LiveTrackingStore;
